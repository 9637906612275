import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./util/react-tabs.css";
// import { TestStore } from "../../recoil/TestStore";
import React, { useEffect } from "react";
import { CourseDetails } from "./courseDetails";

import { selectedTabState } from "../../recoil/atoms/general/selectedTab";
import Calendar from "./calendar";
import { Analytics } from "./analytics";
import { useRecoilValue, useRecoilState } from "recoil";
import { scorecardGraduationState } from "../../recoil/atoms/hsg/scorecardGraduation";
import { GetChosenCourses } from "./util/getChosenCourses";
// import { cleanedCoursesQuery } from "../../recoil/selectors/general/cleanedCourses";
import Transcript from "./transcript";
import { addedLocalSelectedCoursesQuery } from "../../recoil/selectors/general/addedLocalSelectedCourses";
import StudyOverview from "./studyOverview";
import { relevantCisIdsQuery } from "../../recoil/selectors/hsg/relevantCisIds";
// import { coursesHubQuery } from "../../recoil/selectors/general/coursesHub";
import { coursesTakenForRatingState } from "../../recoil/atoms/hsg/coursesTakenForRatings";
import { selectedSemesterState } from "../../recoil/atoms/general/selectedSemester";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../utils/ErrorFallback";

const tabStyle =
  "flex-1 h-10 text-center justify-center items-center flex font-medium lg:font-semibold text-xs lg:text-sm rounded-md text-white bg-neutral mx-1";

const fixedOptions = [
  // {
  //   label: "Spring 2024",
  //   date: "2022-09-19T00:00:00",
  //   termId: "FS 24 - Placeholder",
  //   cisTermId: "FS 24 - Placeholder",
  //   value: "Spring 2024 Placeholder",
  // },
  // {
  //   label: "Fall 2024",
  //   date: "2022-09-19T00:00:00",
  //   termId: "HS 24 - Placeholder",
  //   cisTermId: "HS 24 - Placeholder",
  //   value: "Fall 2024 Placeholder",
  // },
  {
    label: "Spring 2025",
    date: "2022-09-19T00:00:00",
    termId: "FS 25 - Placeholder",
    cisTermId: "FS 25 - Placeholder",
    value: "Spring 2025 Placeholder",
  },
  {
    label: "Fall 2025",
    date: "2022-09-19T00:00:00",
    termId: "HS 25 - Placeholder",
    cisTermId: "HS 25 - Placeholder",
    value: "Fall 2025 Placeholder",
  },
  {
    label: "Spring 2026",
    date: "2022-09-19T00:00:00",
    termId: "FS 26 - Placeholder",
    cisTermId: "FS 26 - Placeholder",
    value: "spring 2026 Placeholder",
  },
  {
    label: "Fall 2026",
    date: "2022-09-19T00:00:00",
    termId: "HS 26 - Placeholder",
    cisTermId: "HS 26 - Placeholder",
    value: "Fall 2026 Placeholder",
  },
  {
    label: "Spring 2027",
    date: "2022-09-19T00:00:00",
    termId: "FS 27 - Placeholder",
    cisTermId: "FS 27 - Placeholder",
    value: "Spring 2027 Placeholder",
  },
  {
    label: "Fall 2027",
    date: "2022-09-19T00:00:00",
    termId: "HS 27 - Placeholder",
    cisTermId: "HS 27 - Placeholder",
    value: "Fall 2027 Placeholder",
  },
];

export function RightCol({ styleProps }) {
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);

  const [scorecardGraduation] = useRecoilState(scorecardGraduationState);
  // const sortedCourses = useRecoilValue(cleanedCoursesQuery);
  const sortedCourses = useRecoilValue(addedLocalSelectedCoursesQuery);
  const [finalCourses, setFinalCourses] = React.useState([]);
  //const data = scorecardGraduation.items[0];
  //console.log("x7 data", data)

  // Combine items from scorecardGraduation.items[0] and scorecardGraduation.items[1]
  // fix for changes due to scorecard API switch (Summer 2024)
  const data = React.useMemo(() => {
    if (!scorecardGraduation.items || scorecardGraduation.items.length < 2) {
      return [scorecardGraduation.items[0] || {}];
    }

    return [scorecardGraduation.items[0], scorecardGraduation.items[1]];
  }, [scorecardGraduation]);
  console.log("x9 data", data);

  //let currItem = data;
  let options = useRecoilValue(relevantCisIdsQuery);
  const [openCourses] = useRecoilState(coursesTakenForRatingState);
  const [selectedSemester] = useRecoilState(selectedSemesterState);
  // console.debug("openCourses", openCourses);

  // const test = useRecoilValue(coursesHubQuery)

  // function filteredHierarchy(hierarchy) {
  //   const index = hierarchy.lastIndexOf(".", hierarchy.lastIndexOf(".") - 1);
  //   return hierarchy.substring(0, index + 1);
  // }

  function semesterNameCleanUp(semesterName) {
    let semester = semesterName.replace(" Placeholder", "");
    semester = semester.replace("Spring", "FS");
    semester = semester.replace("Fall", "HS");
    semester = semester.replace("Spring Semester", "FS");
    semester = semester.replace("Fall Semester", "HS");
    semester = semester.replace("Herbstsemester", "HS");
    semester = semester.replace("Frühjahrssemester", "FS");
    semester =
      semester.split(" ")[0] +
      " " +
      semester.split(" ")[1].toString().substring(2, 4);
    return semester;
  }

  useEffect(() => {
    // CHECK IF THIS IS CORRECT?!?!?L
    // console.debug("help", sortedCourses)
    const lastSemester = sortedCourses[sortedCourses.length - 8].data;
    const currSemester = sortedCourses[sortedCourses.length - 7].data;
    const placeholderSemesterOne = sortedCourses[sortedCourses.length - 6].data;
    const placeholderSemesterTwo = sortedCourses[sortedCourses.length - 5].data;
    const placeholderSemesterThree =
      sortedCourses[sortedCourses.length - 4].data;
    const placeholderSemesterFour =
      sortedCourses[sortedCourses.length - 3].data;
    const placeholderSemesterFive =
      sortedCourses[sortedCourses.length - 2].data;
    const placeholderSemesterSix = sortedCourses[sortedCourses.length - 1].data;
    let allClassifications = [];
    getAllHierarchies(data);
    let finalOptions = [...options, ...fixedOptions];
    // console.log('help',finalOptions, options, fixedOptions)
    const lastTwoSemesters = [
      ...lastSemester,
      ...currSemester,
      ...placeholderSemesterOne,
      ...placeholderSemesterTwo,
      ...placeholderSemesterThree,
      ...placeholderSemesterFour,
      ...placeholderSemesterFive,
      ...placeholderSemesterSix,
    ];
    const chosenCourses = GetChosenCourses(lastTwoSemesters, finalOptions);
    let testCourses = [];

    // get all hierarchies from scorecardGraduation
    function getAllHierarchies(dataArray) {
      dataArray.forEach((data) => {
        if (data.items) {
          processItems(data.items);
        }
      });

      function processItems(items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].isTitle === true) {
            let temp = {
              classification: items[i].description,
              hierarchy: items[i].hierarchy,
            };
            allClassifications.push(temp);
          }
          if (items[i].items) {
            processItems(items[i].items);
          }
        }
      }
    }

    function getHierarchyFromClassification(classification) {
      let hierarchy = "";
      allClassifications.map((item) => {
        if (item.classification === classification) {
          hierarchy = item.hierarchy;
        }
        return null;
      });
      return hierarchy;
    }

    // get all parents of a course from scorecardGraduation

    // traverse the nested structure scorecardGraduation and find the course where the scorecardGraduation description matches the course classification, when found add all the parents to the course

    chosenCourses.map((course) => {
      const temp = {
        name: course.shortName,
        hierarchy: getHierarchyFromClassification(course.classification),
        semester: semesterNameCleanUp(course.semesterName) || "NA",
        grade: null,
        maxCredits: course.credits / 100,
        sumOfCredits: course.credits / 100,
        minCredits: course.credits / 100,
        mncp: null,
        lang: course.courseLanguage.code,
        classification: course.classification,
        status: checkIfCourseIsToBeRated(course.shortName)
          ? "toBeRated"
          : course.allocated
          ? "allocated"
          : "selected",
        overlapping: course.overlapping ? true : false,
        fullEvent: course,
        parents: [],
      };
      return testCourses.push(temp);
    });

    function findNestedProperty(dataArray) {
      dataArray.forEach((data) => {
        if (data.items) {
          processItems(data.items, data.description);
        }
      });

      function processItems(items, parentDescription) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].isTitle === false) {
            const temp = {
              name: items[i].description || items[i].shortName,
              hierarchy: items[i].hierarchy,
              semester: items[i].semester,
              grade: parseFloat(items[i].mark),
              maxCredits: parseFloat(items[i].maxCredits),
              sumOfCredits: parseFloat(items[i].sumOfCredits),
              minCredits: parseFloat(items[i].minCredits),
              mncp: parseFloat(items[i].mncp),
              lang: items[i].achievementLanguage,
              status: checkIfCourseIsToBeRated(
                items[i].description || items[i].shortName
              )
                ? "toBeRated"
                : "completed",
              classification: parentDescription,
              sumOfWeightedMarks:
                parseFloat(items[i].sumOfCredits) * parseFloat(items[i].mark),
            };
            testCourses.push(temp);
          } else {
            if (items[i].items) {
              processItems(items[i].items, items[i].description);
            }
          }
        }
      }
    }

    function checkIfCourseIsToBeRated(courseName) {
      for (let i = 0; i < openCourses.length; i++) {
        if (openCourses[i].courseName === courseName) {
          return true;
        }
      }
      return false;
    }

    findNestedProperty(data);
    // console.debug("testCourses", testCourses);

    // if two courses have the same name, replace the classification of the course with a fullEvent with the classification of the course without a fullEvent, same with hierarchy
    function replaceClassification() {
      for (let i = 0; i < testCourses.length; i++) {
        for (let j = 0; j < testCourses.length; j++) {
          if (
            testCourses[i].name === testCourses[j].name &&
            testCourses[i].fullEvent &&
            !testCourses[j].fullEvent
          ) {
            testCourses[i].classification = testCourses[j].classification;
            testCourses[i].hierarchy = testCourses[j].hierarchy;
          }
        }
      }
    }

    replaceClassification();

    // check if the semester to check is before the current semester
    function checkSemesterOrder(semToCheck, currSem) {
      let semToCheckPart = semToCheck.split(" ")[0];
      let semToCheckYear = semToCheck.split(" ")[1];
      let currSemPart = currSem.split(" ")[0];
      let currSemYear = currSem.split(" ")[1];

      if (currSemYear > semToCheckYear) {
        return false;
      } else if (
        currSemYear === semToCheckYear &&
        currSemPart > semToCheckPart
      ) {
        return false;
      }
      return true;
      // console.debug(semToCheckYear, semToCheckPart, currSemYear, currSemPart)
    }

    // filter all courses where fullEvent.courseNumber does not end with "1.00"
    function filterCoursesByCourseNumber(course) {
      let temp = course.filter((course) => {
        // console.debug("help7", course)
        // ugly fix to filter double courses, if their semester is smaller than the current semester they must be falsely there because we get their values from the transcript
        // checkSemesterOrder(course.semester, "HS 23")
        if (!course.fullEvent && !isNaN(course.grade)) {
          return true;
        } else if (!checkSemesterOrder(course.semester, "HS 24")) {
          //#semesterchange
          return false;
        } else if (
          course.fullEvent &&
          course.fullEvent.id &&
          // regex to check if courseNumber ends with "1.0x"
          /.*,1.0.$/.test(course.fullEvent.courseNumber) |
            course.fullEvent.id.startsWith("PLACEHOLDER")
        ) {
          return true;
        }
        return false;
      });

      return temp;
    }
    setFinalCourses(filterCoursesByCourseNumber(testCourses));
  }, [sortedCourses, data, options, openCourses]);

  return (
    <div className={styleProps}>
      <Tabs
        className="flex flex-col w-full h-full overflow-hidden"
        selectedIndex={selectedTab}
        onSelect={(index) => setSelectedTab(index)}
      >
        <React.Suspense fallback={<div>loading...</div>}>
          <TabList className="flex w-full p-1 pt-2">
            <Tab className={tabStyle}>Course Details</Tab>
            <Tab className={tabStyle}>Calendar</Tab>
            <Tab className={tabStyle}>
              {semesterNameCleanUp(selectedSemester.label)} Summary
            </Tab>
            <Tab className={tabStyle}>Transcript</Tab>
            <Tab className={tabStyle}>Study Overview</Tab>
          </TabList>
        </React.Suspense>
        <React.Suspense fallback={<div>loading...</div>}>
          <TabPanel className="">
            <React.Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <CourseDetails />
              </ErrorBoundary>
            </React.Suspense>
          </TabPanel>
          <TabPanel className="">
            <React.Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Calendar />
              </ErrorBoundary>
            </React.Suspense>
          </TabPanel>
          <TabPanel className="">
            <React.Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Analytics finalCourses={finalCourses} />
              </ErrorBoundary>
            </React.Suspense>
          </TabPanel>
          <TabPanel className="scrollbar-hide">
            <React.Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Transcript
                  currItem={data[0]}
                  finalCourses={finalCourses.filter(
                    (course) => course.classification === "Core Studies"
                  )}
                  data={data[0]}
                />
                <Transcript
                  currItem={data[1]}
                  finalCourses={finalCourses.filter(
                    (course) => course.classification === "Contextual Studies"
                  )}
                  data={data[1]}
                />
              </ErrorBoundary>
            </React.Suspense>
          </TabPanel>
          <TabPanel className="scrollbar-hide">
            <React.Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StudyOverview finalCourses={finalCourses} data={data} />
              </ErrorBoundary>
            </React.Suspense>
          </TabPanel>
        </React.Suspense>
      </Tabs>
    </div>
  );
}
